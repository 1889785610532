import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import App from './App';
import XHR from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_es from './translations/es/common.json';
import common_en from './translations/en/common.json';

import pricing_es from './translations/es/pricing.json';
import pricing_en from './translations/en/pricing.json';

import './styles/styles.scss';
import EnvironmentBanner from './components/EnvironmentBanner/EnvironmentBanner';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { isMobile } from 'react-device-detect';

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    interpolation: { escapeValue: false },
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lng',
    },
    supportedLngs: ['en', 'es'],
    fallbackLng: 'en',
    nonExplicitSupportedLngs: true,
    resources: {
      en: {
        common: common_en,
        pricing: pricing_en
      },
      es: {
        common: common_es,
        pricing: pricing_es
      },
    },
  });

window.intercomSettings = {
  app_id: 'nzfrh5ia',
  hide_default_launcher: !!isMobile || ['/collaborations', '/card'].includes(window.location.pathname),
};

ReactDOM.render(
  <React.StrictMode>
    <EnvironmentBanner />
    <I18nextProvider i18n={i18next}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#00A742',
            colorPrimaryBg: '#FCFCFC',
            colorPrimaryBgHover: '#F2F5F9',
            colorPrimaryBorder: '#00A742',
            colorSuccess: '#00C14E',
            colorSuccessBg: '#F9FCF9',
            colorSuccessBorder: '#A0DAB3',
            colorSuccessHover: '#36C666',
            colorSuccessBorderHover: '#36C666',
            colorWarningBg: '#FFFFF2',
            colorErrorBg: '#FFF6F6',
            colorTextBase: '#232542',
            sizeUnit: 4,
            borderRadius: 8,
            wireframe: true,
            controlHeight: 40,
          },
          components: {
            Typography: {
              fontFamilyCode: 'Open Sans',
            },
            Rate: {
              fontFamily: 'Open Sans',
              yellow6: 'A5DFA5',
            },
            Slider: {
              handleActiveColor: 'rgb(165, 223, 165)',
              colorPrimaryBorder: 'rgb(165, 223, 165)',
              colorPrimaryBorderHover: 'rgb(35, 37, 66)',
              fontFamily: 'Open Sans',
            },
            Menu: {
              colorPrimary: 'rgb(35, 37, 66)',
              colorPrimaryBorder: 'rgb(35, 37, 66)',
            },
            Tabs: {
              inkBarColor: 'rgb(0, 167, 66)',
              itemActiveColor: 'rgb(0, 128, 55)',
              itemHoverColor: 'rgb(0, 128, 55)',
              itemSelectedColor: 'rgb(0, 167, 66)',
              titleFontSize: 16,
              titleFontSizeSM: 16,
            },
            Input: {
              activeBg: '#F2F5F9',
            },
            Message: {
              colorInfo: 'rgb(172, 174, 191)',
            },
            Button: {
              groupBorderColor: 'rgb(0, 167, 66)',
              fontWeight: 600,
            },
            Table: {
              headerBg: '#fff',
              cellPaddingInline: 8,
              selectionColumnWidth: 16,
              cellFontSize: 16,
              rowHoverBg: '#F3FBF7',
              rowSelectedBg: '#d9f2e6',
              rowSelectedHoverBg: '#d9f2e6',
              headerSortActiveBg: '#d9f2e6',
              headerSortHoverBg: '#d9f2e6',
              bodySortBg: '#F3FBF7',
              headerFilterHoverBg: '#d9f2e6',
            },
            Modal: {
              titleFontSize: 20,
            },
            Cascader: {
              colorHighlight: 'rgb(82, 196, 26)',
            },
            Collapse: {
              headerBg: '#fff',
            },
            Layout: {
              bodyBg: '#fff',
            },
          },
        }}
      >
        <App />
      </ConfigProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

const serviceWorkerConfig = {
  onUpdate(registration) {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  },
};

serviceWorkerRegistration.register(serviceWorkerConfig);
